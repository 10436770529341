<template>
  <div class="content-right rounded-3">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
        </li>
        <li class="breadcrumb-item active">
          <router-link :to="{ name: 'leaves' }">Leaves</router-link>
        </li>
        <li class="breadcrumb-item active">EditLeave</li>
      </ol>
    </nav>
    <div
      class="content-body"
      id="fullHeight"
      style="min-height: calc(100vh - 135px)"
    >
      <div class="row">
        <div class="col-md-11">
          <h2 class="title2">Update Leave</h2>
        </div>
        <div class="col-md-1 text-right">
          <router-link :to="{ name: 'leaves' }" class="btn btn-outline-primary"
            >Back</router-link
          >
        </div>
      </div>
      <div v-if="this.$store.state.loader">
        <loader
          object="#f74b3d"
          color1="#ffffff"
          color2="#17fd3d"
          size="5"
          speed="2"
          bg="#343a40"
          objectbg="#999793"
          opacity="80"
          disableScrolling="false"
          name="spinning"
        ></loader>
      </div>
      <form v-else @submit.prevent="onSubmit">
        <div class="row">
          <div class="col-md-6">
            <div class="card">
              <div class="card-header">
                <h2>Update Leave</h2>
              </div>
              <div class="card-body">
                <div class="mb-3">
                  <label class="form-label">Employee</label>
                  <input
                    class="form-select"
                    aria-label="Default select example"
                    v-model="this.form.employee"
                    readonly="true"
                  />
                </div>
                <div class="mb-3">
                  <label for="firstName" class="form-label">Leave Group</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="this.form.type"
                    readonly="true"
                  />
                </div>
                <div class="row mb-3 request-leave">
                  <div class="col-md-12">
                    <label class="form-label">Leave Type</label>
                    <div class="form-group">
                      <b-form-radio
                        id="half_day"
                        v-model="form.leave_type"
                        type="radio"
                        value="HALF DAY"
                        readonly
                        required
                        >Half day</b-form-radio
                      >
                      <b-form-radio
                        id="full_day"
                        v-model="form.leave_type"
                        type="radio"
                        value="FULL DAY"
                        required
                        >Full day</b-form-radio
                      >
                      <p class="error" v-if="$v.form.leave_type.$error == true">
                        Leave Type is required
                      </p>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label">Leave From</label>
                      <datepicker
                        :typeable="true"
                        placeholder="Select Date"
                        :format="customFormatter"
                        :highlighted="highlighted"
                        class="form-control"
                        v-model="$v.form.from.$model"
                        :validator="$v.form.from"
                        @input="leaveFrom()"
                      ></datepicker>
                    </div>
                  </div>
                  <div class="mb-3 col-md-6">
                    <label class="form-label">Start Time</label>
                    <div class="">
                      <vue-timepicker
                        format="hh:mm A"
                        v-model="$v.form.start_time.$model"
                        :validator="$v.form.start_time"
                        auto-scroll
                        @change="timeChangeHandler($event, 'start_time')"
                      ></vue-timepicker>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label">Leave To</label>
                      <datepicker
                        :typeable="true"
                        placeholder="Select Date"
                        :format="customFormatter"
                        :highlighted="highlighted"
                        class="form-control"
                        @opened="LeaveFromHandler()"
                        v-model="$v.form.to.$model"
                        :validator="$v.form.to"
                        @input="leaveTo()"
                        :disabled-dates="disabledDates"
                      ></datepicker>
                    </div>
                  </div>
                  <div class="mb-3 col-md-6">
                    <label class="form-label">End Time</label>
                    <div class="">
                      <vue-timepicker
                        format="hh:mm A"
                        v-model="$v.form.end_time.$model"
                        :validator="$v.form.end_time"
                        auto-scroll
                        @change="timeChangeHandler($event, 'end_time')"
                      ></vue-timepicker>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="mb-3 col-md-6">
                    <label class="form-label">Return Date</label>
                    <datepicker
                      :typeable="true"
                      placeholder="Select Date"
                      :format="customFormatter"
                      :highlighted="highlighted"
                      @opened="leaveToHandler()"
                      class="form-control"
                      v-model="$v.form.returnDate.$model"
                      :validator="$v.form.returnDate"
                      @input="leaveDaysCalculation()"
                      :disabled-dates="disableReturnDates"
                    ></datepicker>
                  </div>
                  <div class="mb-3 col-md-6">
                    <label class="form-label">Leaves Days</label>
                    <div>
                      <input
                        type="text"
                        id="inputValue"
                        v-model="$v.form.leaveCount.$model"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div class="mb-3">
                  <label class="form-label">Reason</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="$v.form.reason.$model"
                  />
                </div>
                <div class="mb-3">
                  <label class="form-label">Remarks</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="$v.form.remarks.$model"
                  />
                </div>

                <div class="mb-3">
                  <label class="form-label">Status</label>
                  <b-form-select
                    class="form-select"
                    aria-label="Default select example"
                    v-model="$v.form.status.$model"
                    :options="status"
                  ></b-form-select>
                  <span class="error" v-if="$v.form.status.$error == true"
                    >Status is required</span
                  >
                </div>
                <div class="text-right">
                  <button type="submit" class="btn btn-outline-primary space">
                    Update
                  </button>
                  <router-link
                    :to="{ name: 'leaves' }"
                    class="btn btn-outline-primary"
                    >Cancel</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import axios from "axios";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
import Datepicker from "vuejs-datepicker";
import moment from "moment";

export default {
  props: ["id"],
  components: { Datepicker, VueTimepicker },
  data() {
    return {
      form: {
        id: null,
        employee: null,
        type: null,
        leave_type: null,
        start_time: null,
        end_time: null,
        from: null,
        to: null,
        returnDate: null,
        reason: null,
        remarks: null,
        status: null,
        leaveCount: null,
      },
      status: [
        {
          value: "Approved",
          text: "Approved",
        },
        {
          value: "Rejected",
          text: "Rejected",
        },
      ],
      highlighted: { dates: [new Date("yyyy-MM-dd")] },
      disabledDates: {
        to: new Date(Date.now() - 8640000),
      },
      disableReturnDates: {
        to: new Date(Date.now() - 8640000),
      },
    };
  },
  validations: {
    form: {
      leaveCount: {},
      status: {
        required,
      },
      leave_type: {
        required,
      },

      from: {
        required,
      },

      to: {
        required,
      },
      returnDate: {
        required,
      },
      start_time: {},
      end_time: {},
      reason: {},
      remarks: {},
    },
  },
  created() {
    this.$store.dispatch("switchLoader", true);
    this.$store.dispatch("loadLeavesdata");
    this.$store.dispatch("loadHolidaysdata");
    this.$store.dispatch("loadSpecialWorkingDays");
  },
  mounted() {
    let empData = this.$store.state.leavesData.leaves.filter((leave) => {
      return leave.id == this.$route.params.id;
    })[0];
    this.form.id = empData.id;
    this.form.employee = empData.employee;
    this.form.type = empData.type;
    this.form.leave_type = empData.leave_type;
    this.form.from = empData.leavefrom;
    this.form.to = empData.leaveto;
    this.form.start_time = empData.start_time;
    this.form.end_time = empData.end_time;
    this.form.returnDate = empData.returndate;
    this.form.reason = empData.reason;
    this.form.remarks = empData.remarks;
    this.form.status = empData.status;
    this.form.leaveCount = empData.leavedays;
    this.disabledDates.to = new Date(this.form.from);
    this.disableReturnDates.to = new Date(this.form.to);
  },

  methods: {
    leaveFrom() {
      if (this.form.from != "") {
        this.disabledDates.to = new Date(this.form.from);
        if (new Date(this.form.from) > new Date(this.form.to)) {
          this.form.to = null;
          this.form.returnDate = null;
        }
        var leaveStart = new Date(this.form.from);
        var leaveEnd = new Date(this.form.to);
        var leavesDaysCount = [];
        var specialWorkingDayCount = [];
        var specialWorkingDays = this.$store.state.specialWorkingDays.data;
        var finalSpecialWorkingDays = specialWorkingDays.map(function (
          specialWorkingDate
        ) {
          return moment(specialWorkingDate.date).format("yyyy-MM-DD");
        });
        for (
          var leavesDay = leaveStart;
          leavesDay <= leaveEnd;
          leavesDay.setDate(leavesDay.getDate() + 1)
        ) {
          var leaveDays = moment(leavesDay).format("yyyy-MM-DD");
          if (finalSpecialWorkingDays.indexOf(leaveDays) != -1) {
            specialWorkingDayCount.push(leaveDays);
          }
          if (leavesDay.getDay() == 0 || leavesDay.getDay() == 6) {
            continue;
          }
          var count = new Date(
            leavesDay.getFullYear(),
            leavesDay.getMonth(),
            leavesDay.getDate()
          );
          leavesDaysCount.push(count);
        }
        var leavesDays = leavesDaysCount.map(function (leavedates) {
          return moment(leavedates).format("yyyy-MM-DD");
        });
        var holidayData = this.$store.state.holidaysData;
        var finalHolidayData = holidayData.map(function (dateval) {
          var data = new Date(dateval.date);
          return moment(data).format("yyyy-MM-DD");
        });
        let intersection = finalHolidayData.filter((x) =>
          leavesDays.includes(x)
        );
        var leavedaysCount = leavesDaysCount.length - intersection.length;
        this.form.leaveCount = leavedaysCount + specialWorkingDayCount.length;

        var newLeavesFrom = this.form.from;
        return newLeavesFrom;
      }
    },
    leaveTo() {
      if (this.form.from != "") {
        if (new Date(this.form.to) > new Date(this.form.returnDate)) {
          this.form.returnDate = null;
        }
        this.disableReturnDates.to = new Date(this.form.to);
        var leaveStart = new Date(this.form.from);
        var leaveEnd = new Date(this.form.to);
        var leavesDaysCount = [];
        var specialWorkingDayCount = [];
        var specialWorkingDays = this.$store.state.specialWorkingDays.data;
        var finalSpecialWorkingDays = specialWorkingDays.map(function (
          specialWorkingDate
        ) {
          return moment(specialWorkingDate.date).format("yyyy-MM-DD");
        });
        for (
          var leavesDay = leaveStart;
          leavesDay <= leaveEnd;
          leavesDay.setDate(leavesDay.getDate() + 1)
        ) {
          var leaveDays = moment(leavesDay).format("yyyy-MM-DD");
          if (finalSpecialWorkingDays.indexOf(leaveDays) != -1) {
            specialWorkingDayCount.push(leaveDays);
          }
          if (leavesDay.getDay() == 0 || leavesDay.getDay() == 6) {
            continue;
          }
          var count = new Date(
            leavesDay.getFullYear(),
            leavesDay.getMonth(),
            leavesDay.getDate()
          );
          leavesDaysCount.push(count);
        }
        var leavesDays = leavesDaysCount.map(function (leavedates) {
          return moment(leavedates).format("yyyy-MM-DD");
        });
        var holidayData = this.$store.state.holidaysData;
        var finalHolidayData = holidayData.map(function (dateval) {
          var data = new Date(dateval.date);
          return moment(data).format("yyyy-MM-DD");
        });
        let intersection = finalHolidayData.filter((x) =>
          leavesDays.includes(x)
        );
        var leavedaysCount = leavesDaysCount.length - intersection.length;
        this.form.leaveCount = leavedaysCount + specialWorkingDayCount.length;
      }
    },

    leaveDaysCalculation() {
      if (this.form.from != "" && this.form.to) {
        var leaveStart = new Date(this.form.from);
        var leaveEnd = new Date(this.form.to);
        var leavesDaysCount = [];
        var specialWorkingDayCount = [];
        var specialWorkingDays = this.$store.state.specialWorkingDays.data;
        var finalSpecialWorkingDays = specialWorkingDays.map(function (
          specialWorkingDate
        ) {
          return moment(specialWorkingDate.date).format("yyyy-MM-DD");
        });
        for (
          var leavesDay = leaveStart;
          leavesDay <= leaveEnd;
          leavesDay.setDate(leavesDay.getDate() + 1)
        ) {
          var leaveDays = moment(leavesDay).format("yyyy-MM-DD");
          if (finalSpecialWorkingDays.indexOf(leaveDays) != -1) {
            specialWorkingDayCount.push(leaveDays);
          }
          if (leavesDay.getDay() == 0 || leavesDay.getDay() == 6) {
            continue;
          }
          var count = new Date(
            leavesDay.getFullYear(),
            leavesDay.getMonth(),
            leavesDay.getDate()
          );
          leavesDaysCount.push(count);
        }
        var leavesDays = leavesDaysCount.map(function (leavedates) {
          return moment(leavedates).format("yyyy-MM-DD");
        });
        var holidayData = this.$store.state.holidaysData;
        var finalHolidayData = holidayData.map(function (dateval) {
          var data = new Date(dateval.date);
          return moment(data).format("yyyy-MM-DD");
        });
        let intersection = finalHolidayData.filter((x) =>
          leavesDays.includes(x)
        );
        var leavedaysCount = leavesDaysCount.length - intersection.length;
        this.form.leaveCount = leavedaysCount + specialWorkingDayCount.length;
      }
    },
    timeChangeHandler(event, time) {
      if (time == "start_time") {
        this.form.start_time = event.displayTime;
      } else {
        this.form.end_time = event.displayTime;
      }
    },
    customFormatter(date) {
      return moment(date).format("yyyy-MM-DD ");
    },
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      axios
        .post("/leaves/update", this.form)
        .catch(({ response }) => {
          console.log(response);
          this.flashMessage.error({
            message: "Something went wrong!",
            time: 3000,
          });
        })
        .then(({ data }) => {
          console.log(data);
          if (data.success) {
            this.flashMessage.success({
              message: data.success,
              time: 3000,
            });
            this.$router.push({ name: "leaves" });
          } else {
            this.flashMessage.error({
              message: data.error,
              time: 3000,
            });
          }
        });
    },
  },
};
</script>
